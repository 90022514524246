export class ViewUtil {

    static getCSSVar(variableName) {
    
        // ルート要素のスタイルを取得
        const rootStyles = getComputedStyle(document.documentElement);
    
        // CSS変数の値を取得
        const value = rootStyles.getPropertyValue(variableName).trim();
    
        // 取得した値を返す
        return value;
    }
    
    // メールアドレスを暗号化して表示
    static mailConverter(addr) {
        var str = "",
            str_as = "";
        for (var i = 0; i < addr.length; i++) {
            str_as = addr.charCodeAt(i);
            str += String.fromCharCode(str_as + 1);
        }
        return str;
    }
    
    static calculateColumnCount(parentWidth, itemWidth) {
        const columnCount = Math.floor(parentWidth / itemWidth);
        return columnCount;
    }
    
}