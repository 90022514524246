/*! Yohei Hashimoto's tr-page System
 * https://yhashimoto.com
 * Copyright Yohei Hashimoto */
import { M } from '../../main.js';
import $ from 'jquery';
import { ViewUtil } from '../../utils/view.js';
import { RouterUtil } from '../../utils/router.js';
import { NumberUtil } from '../../utils/number.js';

let pageLeaveDelay = 1000;
const mainFrame = document.getElementById('main');

$(() => {

    const links = document.querySelectorAll("a:not(.-no-tr), .nav button");

    // 遷移までのTransitionタイムを取得
    pageLeaveDelay = parseInt(NumberUtil.convertToMilliseconds(ViewUtil.getCSSVar('--leave-time'))) + 5;
    //console.log(pageLeaveDelay);

    links.forEach(function(link) {
        link.addEventListener("click", onPageTransition);
    });

    window.addEventListener('popstate', function() {
        mainFrame.classList.remove('-page-leave');
    });

    function onPageTransition(e) {
        //console.log('onPageTransition');
        //isNav = false;
        $('.main-frame').removeClass('-nav-open');
        $('.main-frame').addClass('page-loading');
        
        const $a = e.target.closest('a');
        e.preventDefault(); // デフォルトのリンク動作を無効化
    
        const href = $a.href;
    
        //console.log(href);
    
        // `target="_blank"`が設定されている場合
        if ($a.target === "_blank") {
            window.open(href, "_blank");
            return; // これ以上の処理をしない
        }
    
        if (RouterUtil.isExternalLink(href)) {
            window.open(href, "_blank");
    
        // 内部リンクの場合の処理
        } else {
            $('.base-frame').addClass('page-leave page-leave-active');
              console.log('add page-leave');
            
            setTimeout(function() {
                $('.base-frame').removeClass('page-leave');
                $('.base-frame').addClass('page-leave-to');
                console.log('add page-leave-to');
            }, 1);
    
            setTimeout(function() {
                window.location.href = href;
            }, pageLeaveDelay);
        }
    }

});
