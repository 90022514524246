import $ from 'jquery';
export { $ };
import 'jquery-inview';
import { M } from '../main.js';

import Swiper from "swiper";
import { EffectFade, Autoplay, Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';

import { ViewUtil } from '../utils/view.js';
import '../modules/transition/pages.js';

Swiper.use([Navigation]);

let scrollLastTop = 0;
let resizeTimeout;
let mainFrame;
let swiperSettings = {
    v: 0.015,
    r: 1,
    vr: 1,
}
let pageEnterDelay = 1000;
let pageEnterLoaderTime = 1000;

document.addEventListener('DOMContentLoaded', () => {
    const mainFrame = document.getElementById('main');
    if (mainFrame) { // nullチェックを追加
        mainFrame.classList.add('page-loading');
    }
});

window.addEventListener('pageshow', (event) => {
    init();    
});

window.addEventListener('focus', () => {
    init();    
});

function init(){
        
    // Transitionタイムを取得
    pageEnterDelay = parseInt(ViewUtil.getCSSVar('--enter-time')) + 5;
    pageEnterLoaderTime = parseInt(ViewUtil.getCSSVar('--enter-loader-time')) + 5;

    //たまに最初からloadedがつくので強制的にはずす
    $('.main-frame').removeClass('-loaded wf-active');
    $('.base-frame').removeClass('page-leave page-leave-to page-leave-active');
    
    setTimeout(function() {
        $('.main-frame').removeClass('-loaded wf-active');
        $('.main-frame').addClass('page-loading');
        $('.base-frame').removeClass('page-leave page-leave-to page-leave-active');
    }, 2);

    setTimeout(function() {
        //$('html')classList.add('-transition-ready');
        $('.main-frame').addClass('-loaded');
        //mainFrame.style.opacity = '1';
        
        console.log('1. -loaded page-enter');
    
        $('.base-frame').addClass('page-enter-active');
        $('.base-frame').addClass('page-enter');
        
        setTimeout(function() {
            $('.base-frame').removeClass('page-enter');
            $('.base-frame').addClass('page-enter-to');
            console.log('2. page-enter-to');
        
            setTimeout(function() {
                $('.base-frame').removeClass('page-enter-to');
                $('.base-frame').removeClass('page-enter-active');
                console.log('3. remove page-enter-to');
            }, pageEnterDelay);
        
            setTimeout(function() {
                $('.main-frame').removeClass('page-loading');
                console.log('4. remove page-loading');
            }, pageEnterLoaderTime);
        }, 3);
    }, 5);
        
    setTimeout(function() {
        $('.base-frame').removeClass('page-enter-active page-enter-to page-enter page-leave page-leave-to page-leave-active');
        $('.main-frame').removeClass('page-loading');
        console.log('5. force remove page-loading');
    }, 4000);
    
}

$(() => {
    mainFrame = document.getElementById('main');

    onResize();
    onScroll();
    
    window.addEventListener('resize', onResize);
    window.addEventListener('scroll', () => {
        requestAnimationFrame(onScroll);
    });
    
    $('.-js-toggle-nav').on('click', function () {
        $('.main-frame').toggleClass('-nav-open');
    });
    
    $('.-js-nav-close').on('click', function () {
        $('.main-frame').removeClass('-nav-open');
    });
    

    // Inview ////////////////////////////////////////

    $('.-inview').one('inview', function(e, isInView) {
        if (isInView) {
            $(this).stop().addClass('-inview-active');
        }
    });
    
    var swiperHomeHero = new Swiper('.-js-swiper-mv', {
        modules: [ EffectFade, Autoplay ],
        grabCursor: true,
        speed: 800,
        autoplay: {
            delay: 2500,
            pauseOnMouseEnter: true,
            disableOnInteraction: false, 
        },
        loop: true,
        effect: "fade",
        touchRatio: 1,
        threshold: 0,
        allowTouchMove: true,
        mousewheel: {
            forceToAxis: true,
        },
        on: {
        }
    });
    
    function swiperSlideSetting(count) {
        return {
            //touchStartPreventDefault: false,
            slidesPerView: "auto",
            centeredSlides: true,
            grabCursor: true,
            spaceBetween: 12,
            roundLengths: true,
            speed: 600,
            loop: true,
            touchRatio: 1,
            threshold: 10,
            allowTouchMove: true,
            mousewheel: {
                forceToAxis: true,
            },
            navigation: {
                nextEl: `#swiper-button-next-${count}`,
                prevEl: `#swiper-button-prev-${count}`,
            },
        };
    }        

    let swiperSlide0 = new Swiper('#swiper-0', swiperSlideSetting(0));
    let swiperSlide1 = new Swiper('#swiper-1', swiperSlideSetting(1));
    let swiperSlide2 = new Swiper('#swiper-2', swiperSlideSetting(2));
    let swiperSlide3 = new Swiper('#swiper-3', swiperSlideSetting(3));
    let swiperSlide5 = new Swiper('#swiper-4', swiperSlideSetting(4));
    let swiperSlide6 = new Swiper('#swiper-5', swiperSlideSetting(5));
    
    // Intersection Observerの設定
    const observerOptions = {
    root: null,
    threshold: 0.5, // 50%以上表示されている場合に"交差"とみなす
    };
    
    const videoElements = document.querySelectorAll('video');
    
    // Intersection Observerで画面内外を検知
    const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        const video = entry.target;
        if (entry.isIntersecting) {
        video.play();
        } else {
        video.pause();
        }
    });
    }, observerOptions);
    
    // 全てのvideo要素を監視対象に追加
    videoElements.forEach((video) => {
    observer.observe(video);
    });
    
    
    // Post End

    var swiperHomeUpdates = new Swiper('.-js-sp-home-updates', {
        modules: [ EffectFade, Autoplay ],
        slidesPerView: "auto",
        spaceBetween: 0,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false, 
        },
        speed: 600,
        roundLengths: true,
        touchRatio: 1,
        threshold: 10,
        allowTouchMove: true,
        on: {
            slideChange: function () {
                // Swiperのアクティブなスライドのインデックスを取得
                var activeIndex = this.activeIndex + 1;
                // `.sp-home-updates`要素に `data-active` 属性を設定
                document.querySelector('.sp-home-updates').setAttribute('data-active', activeIndex);
            }
        }
    });
    
    
    var swiperCol1 = new Swiper('.-js-swiper-col1', {
        modules: [ Autoplay ],
        slidesPerView: 1,
        grabCursor: true,
        loop: true,
        autoplay: {
            delay: 6000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false, 
        },
        speed: 800,
        touchRatio: 1,
        threshold: 0,
        allowTouchMove: true,
        mousewheel: {
            forceToAxis: true,
        },
        on: {
        }
    });
    
    
    // Popovers
    
    $('.-js-popover-video').on('click', function () {
        // ボタンのdata-video属性から値を取得
        const videoElement = $('#popover-video-body');
        let videoPath = $(this).data('video');
        let size = $(this).data('video-size');
        
        // video要素のsrc属性にパスをセット
        $('#popover-video-body').attr('src', videoPath);
        videoElement.attr('src', videoPath);
        
        // 動画のメタデータが読み込まれたときに幅と高さを取得
        videoElement.on('loadedmetadata', function () {
            var videoWidth = this.videoWidth;
            var videoHeight = this.videoHeight;
            
            $('#popover-video-body').css({
                maxWidth: videoWidth,
                maxHeight: videoHeight,
                aspectRatio: videoWidth / videoHeight
            });
            
            $('.popover-video').css({
                aspectRatio: videoWidth / videoHeight
            });
        });
    });
    
    $('.-js-popover-img').on('click', function () {
        // 押されたボタン内の.image要素を探す
        var $image = $(this).find('.image img');
        let zoomPath = $(this).data('zoom');
        
        const srcs = getSrcset(zoomPath);
        
        // ポップオーバー内の画像要素を更新        
        $('#popover-img .image img').attr('src', srcs.src);
        $('#popover-img .image img').attr('srcset', srcs.srcset);
        $('#popover-img .image source').attr('src', srcs.srcsetWebp);
        
    });
    
});

const onScroll = () => {
    if (window.scrollY < scrollLastTop) {
        M.scrollIsDown = false;
    } else {
        M.scrollIsDown = true;
    }

    if (window.scrollY >= 200) {
        mainFrame.classList.add('-scrolled');
    } else {
        mainFrame.classList.remove('-scrolled');
    }

    scrollLastTop = window.scrollY;
}

const onResize = () => {
    if (resizeTimeout) {
        cancelAnimationFrame(resizeTimeout);
    }

    resizeTimeout = requestAnimationFrame(() => {
        resize();
    });
}

const resize = () => {
    const isSP = window.innerWidth > M.spSize ? false : true;
    setWindowSize();
    
    // コンテンツがブラウザよりも小さいか
    const bodyHeight = document.body.scrollHeight;
    const viewportHeight = window.innerHeight;
    
    //console.log(`bodyHeight: ${bodyHeight} / viewportHeight: ${viewportHeight}`);
    
    if (bodyHeight < viewportHeight + 1) {
        document.body.classList.add('-small-height');
    } else {
        document.body.classList.remove('-small-height');
    }
    
    
    if (!isSP) {
        
    } else {
    
    }
}

const setWindowSize = () => {
    let vw = document.documentElement.clientWidth * 0.01;
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
    
function getSrcset(src = '') {
    return {
        src: `\/common\/img\/${src.replace(/\.\w+$/, '@1x$&')}`,
        srcset: `\/common\/img\/${src.replace(/\.\w+$/, '@1x$&')} 1x, \/common\/img\/${src.replace(/\.\w+$/, '@2x$&')} 2x`,
        srcsetWebp: `\/common\/img\/${src.replace(/\.\w+$/, '@1x.webp')} 1x, \/common\/img\/${src.replace(/\.\w+$/, '@2x.webp')} 2x`,
    }
}