export class NumberUtil {
    
    // 値と小数点の桁数をいれて四捨五入
    static roundToDecimal(number, decimalPlaces) {
        if (typeof number !== 'number' || typeof decimalPlaces !== 'number') {
            throw new Error('Both arguments must be numbers');
        }
    
        const multiplier = 10 ** decimalPlaces;
        const roundedNumber = Math.round(number * multiplier) / multiplier;
    
        return roundedNumber;
    }
    
    // 桁数処理
    static zeroPadding(num, length) {
        return (Array(length).join('0') + num).slice(-length);
    }
    
    static convertToMilliseconds(timeString) {
        // ミリ秒表記 (例: "1500ms")
        if (timeString.endsWith('ms')) {
            return parseInt(timeString, 10);
        }
        // 秒表記 (例: "1.5s")
        else if (timeString.endsWith('s')) {
            return parseFloat(timeString) * 1000;
        }
        // 不明な形式
        else {
            throw new Error('Invalid time format');
        }
    }

}