
import { M } from '../main.js';

export class RouterUtil {
    
    static checkLink(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                if (xhr.status === 404) {
                    callback(true); // リンク先が404エラー
                } else {
                    callback(false); // リンク先が存在する
                }
            }
        };
        xhr.open("GET", url, true);
        xhr.send();
    }

    static isExternalLink(href = '') {
        if (
            !href.startsWith(M.baseDomain)
        ) {
            return true;
        } else {
            return false;
        }
    }
    
    static handleLinkTarget(href) {
        if (isExternalLink(href)) {
            window.open(href, "_blank");
    
        } else {
            location = href;
        }
    }
    
    // 配列を受け取ってQuery Parametersに設定する
    static setQueryParameters(param, val) {
        const params = new URLSearchParams();
    
        if (Array.isArray(val)) {
            // 配列の場合はカンマ区切りの文字列に変換
            params.set(param, val.join(','));
        } else {
            // 配列でない場合はそのままセット
            params.set(param, val);
        }
    
        const currentUrlWithoutParams = window.location.origin + window.location.pathname;
        history.pushState({}, '', currentUrlWithoutParams + '?' + params.toString());
    }
    
    // クエリーパラメーターを配列に変換する関数
    static getQueryParamToArray(paramName) {
        const hashString = window.location.hash.substr(1); // 先頭の '#' を取り除く
        const hashParams = new URLSearchParams(hashString);
    
        // もしフラグメントにパスが含まれている場合、それを取り除く
        const pathIndex = hashString.indexOf('?');
        const hashParamsString = pathIndex !== -1 ? hashString.slice(pathIndex + 1) : hashString;
    
        // クエリパラメータを正しく取得
        const queryParams = new URLSearchParams(hashParamsString);
        const paramValue = queryParams.get(paramName);
    
        if (paramValue) {
            return paramValue.split(',');
        } else {
            return [];
        }
    }
    
    static getQueryParam(paramName) {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get(paramName);
    }
    
    
    static getParentQueryParameters() {
        // 親のURLを取得
        var parentUrl = window.parent.location.href;
    
        // URLからクエリーパラメーターを取得
        var queryParams = {};
        var queryString = parentUrl.split('?')[1];
    
        if (queryString) {
            var pairs = queryString.split('&');
            for (var i = 0; i < pairs.length; i++) {
                var pair = pairs[i].split('=');
                var key = decodeURIComponent(pair[0]);
                var value = decodeURIComponent(pair[1] || '');
                queryParams[key] = value;
            }
        }
    
        return queryParams;
    }
    
    static getParentQueryParam(parameterKey) {
        var parentUrl = window.parent.location.href;
        var queryParams = {};
        var queryString = parentUrl.split('?')[1];
    
        if (queryString) {
            var pairs = queryString.split('&');
            for (var i = 0; i < pairs.length; i++) {
                var pair = pairs[i].split('=');
                var key = decodeURIComponent(pair[0]);
                var value = decodeURIComponent(pair[1] || '');
                queryParams[key] = value;
            }
        }
    
        return queryParams[parameterKey];
    }
    
    // httpを削除して表示
    static removeProtocol(url) {
    
        if (!url) {
            return
        }
    
        var v = url.replace('https://', '');
        v = v.replace('http://', '');
        return v;
    }
    
        // ページ内で特定の要素までスクロールする関数
    static scrollToForm(targetId) {
      // 要素を取得
      var formElement = document.getElementById(targetId);
    
      // 要素が存在するか確認
      if (formElement) {
        // 要素までスクロールする
        formElement.scrollIntoView({
          behavior: 'smooth', // スムーズなスクロールを使用する場合
          block: 'start'      // 要素の開始位置までスクロールする
        });
      }
    }
    
}